<template>
	<div class="container" ref="getheight">
		<div class="list">
			<van-list v-model="voteData.loading" :immediate-check="voteData.immediateCheck" @load="getVoteList"
				:finished="voteData.finished" finished-text="没有更多了" :error.sync="voteData.error"
				error-text="请求失败，点击重新加载">
				<div class="relative item" @click="goInitiadetail(index)" v-for="(item,index) in voteData.list"
					:key="index">
					<img src="../../assets/toupiao_icon.png" class="type-icon" />
					<div class="flex-row align-center">
						<img v-if="item.userPic" :src="item.userPic" class="head-icon" />
						<img v-else src="../../assets/mine/head_icon.png" class="head-icon" />
						<div class="flex-column">
							<div class="flex-row align-center">
								<div class="item-username">{{item.userNickName}}</div>
								<div v-if="item.userId== 163598" class="gf">官方</div>
								<div v-if="info.userId== 163666  || info.userId== 163690" class="sgf">项目管理员</div>
							</div>
							<div class="item-time">{{item.createTime}}</div>
						</div>
					</div>
					<div class="flex-row align-center item-title-sec">
						<div class="item-zhiding" v-if="item.isTop == 1">置顶</div>
						<div class="item-title">{{item.title}}</div>
					</div>
					<div class="item-desc">{{item.content}}</div>
					<div class="item-choice-sec relative">
						<div class="item-choice-sec-modal"></div>
						<div class="flex-row align-center item-choice-item" v-for="(ite,ind) in item.voteOptionList"
							:key="ind">
							<div class="item-choice-icon">{{en[ind]}}</div>
							<div class="item-choice-name">{{ite.voteOption}}</div>
						</div>

					</div>
					<div class="flex-row align-center  justify-end item-num-sec">
						<div class="flex-row align-center item-comment">
							<img src="../../assets/comment_icon.png" class="item-comment-icon" />
							<div class="item-comment-num">{{item.commentTotal}}</div>
						</div>
						<div class="flex-row align-center item-collect">
							<img src="../../assets/collect_icon.png" class="item-comment-icon" />
							<div class="item-comment-num">{{item.collectTotal}}</div>
						</div>
						<div class="flex-row align-center item-browse">
							<img src="../../assets/browse_icon.png" class="item-browse-icon" />
							<div class="item-comment-num">{{item.viewTotal}}</div>
						</div>
					</div>
				</div>
			</van-list>
		</div>
	</div>
</template>
<script>
	import config from '../../config/config.js';
	import util from '../../config/util.js';
	import Vue from 'vue';
	import Vant from 'vant';
	import 'vant/lib/index.css';
	Vue.use(Vant);
	export default {
		data() {
			return {
				tab: 1, //顶部切换tab 1话题 2提案 3投票
				tianModal: false, //是否展示提案弹窗
				TopicData: {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					immediateCheck: false
				},
				proposalData: {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					immediateCheck: false,
				},
				voteData: {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					immediateCheck: false,
				},
				itemData: {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					immediateCheck: true,
					index: 0,
					columns: []
				},
				showTopic: false,
				showLogin: false,
				remindCount: -1,
				en: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'],
			}
		},
		methods: {
			goInitiadetail(index) {
				this.$router.push('/initiatedetail?id=' + this.voteData.list[index].id +
					'&projectId=' + this.voteData.list[index].projectId)
			},
			getVoteList() {
				const that = this;
				if (that.voteData.loading) return;
				let params = new FormData()
				params.append('pageNum', this.voteData.pageNum)
				params.append('pageSize', this.voteData.pageSize)
				that.axios.post(config.requestUrl + '/front/forum/getVoteListByUserId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let newList = response.data.obj.list;
						for (let i in newList) {
							newList[i].content = util.getPlainText(newList[i].content)
							newList[i].userAddress = util.formatAddress(newList[i].userAddress)
							newList[i].currentSupportRate = (newList[i].currentSupportRate).toFixed(2);
						}
						if (newList.length < that.voteData.pageSize) that.voteData.finished = true;
						that.voteData.pageNum = that.voteData.pageNum + 1;
						that.voteData.loading = false
						that.voteData.list = that.voteData.list.concat(newList);
						console.log(that.voteData)
					} else {
						that.voteData.error = true
						that.$toast.fail(response.data.msg);
					}
					that.voteData.loading = false;
				}, response => {
					that.voteData.error = true
					that.voteData.loading = false;
					that.$toast.fail('获取失败');
				})
			},
		},
		mounted() {
			this.getVoteList()
		},

	}
</script>


<style scoped="scoped">
	body {
		background: #000000;
	}

	.container {
		background: #F5F5F5;
		min-height: 100%;
		width: 100%;
		min-height: 100%;
		overflow: visible;
		box-sizing: content-box;
	}

	.top-sec {
		width: 750px;
		height: 100px;
		background: #000000;
		box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.35);
		border-radius: 0px 0px 10px 10px;
	}

	.project-icon {
		width: 26px;
		height: 21px;
		margin-left: 30px;
	}

	.project-icon-text {
		font-size: 26px;
		color: #FFFFFF;
		line-height: 26px;
		margin-left: 20px;
	}

	.topic-btn {
		width: 150px;
		height: 50px;
		background: #000000;
		border-radius: 26px;
		border: 1px solid #FFFFFF;
		font-size: 26px;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
		/* margin-left: 262px; */
	}

	.tian-btn {
		width: 150px;
		height: 50px;
		background: #000000;
		border-radius: 26px;
		border: 1px solid #FFFFFF;
		font-size: 26px;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
		margin-left: 20px;
		margin-right: 40px;
	}

	.item {
		width: 710px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 20px 20px 0 20px;
		box-sizing: border-box;
		margin: 20px auto;
	}

	.item2 {
		width: 710px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 20px 20px 0 20px;
		box-sizing: border-box;
		margin: 20px auto;
	}

	.type-icon {
		width: 71px;
		height: 40px;
		position: absolute;
		top: 12px;
		right: -5px;
	}

	.status-icon {
		width: 124px;
		height: 108px;
		position: absolute;
		top: 35px;
		right: 86px;
	}

	.head-icon {
		width: 70px;
		height: 70px;
		margin-right: 20px;
		border-radius: 50%;
	}

	.item-username {
		font-size: 26px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.5);
		line-height: 26px;
	}

	.item-time {
		font-size: 20px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 20px;
		letter-spacing: 1px;
		margin-top: 10px;
	}

	.item-title-sec {
		margin-top: 33px;
	}

	.item-title {
		font-size: 28px;
		color: #000000;
		line-height: 28px;
		letter-spacing: 1px;
	}

	.item-zhiding {
		width: 50px;
		height: 24px;
		background: #FFE3D0;
		border-radius: 2px;
		text-align: center;
		font-size: 18px;
		color: #FA3813;
		line-height: 24px;
		margin-right: 10px;
	}

	.item-desc {
		font-size: 26px;
		color: rgba(0, 0, 0, 0.6);
		line-height: 40px;
		letter-spacing: 1px;
		margin-top: 15px;
		width: 650px;
		max-height: 80px;
		word-break: break-all;
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		/* 这里是超出几行省略 */
	}

	.item-choice-sec {
		width: 670px;
		height: 180px;
		padding: 10px 25px;
		box-sizing: border-box;
		background: #F5F5F5;
	}

	.item-choice-sec-modal {
		position: absolute;
		top: 0;
		left: 0;
		width: 670px;
		height: 180px;
		background-image: linear-gradient(180deg, rgba(251, 251, 251, 0) 0%, rgba(91, 91, 91, 0.92) 100%);
	}

	.item-choice-item {
		width: 620px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 30px;
		margin-top: 10px;
		padding: 10px 30px;
		box-sizing: border-box;
	}

	.item-choice-icon {
		width: 20px;
		height: 20px;
		font-size: 22px;
		font-weight: bold;
		color: #F7B500;
		line-height: 22px;
	}

	.item-choice-name {
		font-size: 22px;
		color: #000000;
		line-height: 22px;
		margin-left: 25px;
	}

	.item-num-sec {
		margin-top: 20px;
		height: 63px;
		border-top: 1px solid #F5F5F5;
		padding-right: 20px;
	}

	.item-comment {}

	.item-comment-icon {
		width: 21px;
		height: 21px;
	}

	.item-browse-icon {
		width: 22px;
		height: 12px;
	}

	.item-comment-num {
		font-size: 16px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 16px;
		margin-left: 10px;
	}

	.item-collect {
		margin-left: 52px;
	}

	.item-browse {
		margin-left: 52px;
	}

	.progress-title-sec {
		margin-top: 32px;
	}

	.progress-icon {
		width: 32px;
		height: 23px;
	}

	.progress-text {
		margin-left: 10px;
		font-size: 26px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 26px;
		letter-spacing: 1px;
	}

	.progress-num-sec {
		margin-top: 20px;
	}

	.progress-bg {
		width: 540px;
		height: 20px;
		background: rgba(0, 0, 0, 0.03);
		border-radius: 10px;
	}

	.progress-persent-finish {
		width: 407px;
		height: 20px;
		background: linear-gradient(90deg, #FFFFFF 0%, #887AFF 100%);
		border-radius: 10px;
	}

	.fire-icon {
		width: 13px;
		height: 16px;
	}

	.progress-persent {
		font-size: 26px;
		color: #000000;
		line-height: 26px;
		letter-spacing: 1px;
		margin-left: 5px;
	}

	.desc-img-sec {
		margin-top: 19px;
	}

	.item-desc-img {
		width: 202px;
		height: 182px;
		border-radius: 10px;
	}

	/* 发起提案弹窗开始 */
	.tian-modal {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
	}

	.tian-modal-con {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 630px;
		height: 394px;
		background: #FFFFFF;
		border-radius: 20px;
	}

	.modal-title {
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 47px;
		margin-top: 39px;
		text-align: center;
	}

	.modal-con {
		width: 550px;
		height: 130px;
		background: rgba(98, 54, 255, 0.1);
		border-radius: 10px;
		text-align: center;
		margin: 30px auto 60px auto;
		padding: 26px 16px;
		box-sizing: border-box;
		font-size: 26px;
		color: #3B2F67;
		line-height: 39px;
	}

	.modal-btn-sec {}

	.cancle-btn {
		width: 315px;
		height: 88px;
		background: #FFFFFF;
		box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
		border-radius: 0px 0px 0px 20px;
		text-align: center;
		font-size: 30px;
		color: #000000;
		line-height: 88px;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}

	.confim-btn {
		width: 315px;
		height: 88px;
		background: #FFFFFF;
		box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
		border-radius: 0px 0px 20px 0px;
		text-align: center;
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 88px;
	}

	/* 发起提案弹窗结束 */
	/* 顶部切换tab部分开始 */
	.tab-sec {
		width: 750px;
		height: 98px;
		background: #FFFFFF;
	}

	.tab-item {
		width: 33.33%;
		height: 98px;
		text-align: center;
	}

	.tab-item-name {
		font-size: 28px;
		color: #000000;
		line-height: 28px;
	}

	.tab-item-name-active {
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 28px;
	}

	.tab-select-border {
		position: absolute;
		bottom: 0px;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 80px;
		height: 5px;
		background: linear-gradient(90deg, #FFFFFF 0%, #6C2FFF 52%, #FFFFFF 100%);
		border-radius: 2px;
	}

	/* 顶部切换tab部分结束 */

	.red-dot {
		width: 14px;
		height: 14px;
		background: #C90909;
		border-radius: 50%;
		position: absolute;
		top: 4px;
		left: 200px;
	}

	.gf {
		margin-left: 10px;
		padding: 0 5px;
		font-size: 10px;
		color: #FFFFFF;
		background: blue;
		border-radius: 4px;
	}
</style>